import React, { useEffect } from "react";
import { Provider } from "react-redux";
import classNames from "classnames";
import { templates } from "@reactivated";
import { BasketLineVariant } from "tsi-common-react/src/apps/checkout/constants";
import { AccountMenu } from "tsi-common-react/src/apps/authentication/components/AccountMenu/index";
import { BasketMenuIcon } from "tsi-common-react/src/apps/checkout/containers/BasketMenuIcon";
import { strToBool } from "tsi-common-react/src/utils/format";
import {
    getPageSetting,
    isPageInsideIFrame as isPageInsideIFrameCheck,
} from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";

import { useIsSSR } from "tsi-common-react/src/utils/ssr";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import Link from "tsi-common-react/src/common/Link";
import { buildMobileNavToggleClickHandler } from "tsi-common-react/src/widgets/rich-navigation";
import { FinancingModalTriggerMinicart } from "../src/Checkout/components/FinancingModalTriggerMinicart";
import { MainMenu } from "../src/Common/containers/MainMenu";
import { store } from "../src/store";

export const Template = (props: templates.SiteHeaderTemplate) => {
    const isSSR = useIsSSR();
    const mainMenuClasses = classNames({
        "site-nav__main-menu": true,
        "rich-navigation--active": props.rich_navigation,
    });
    const enableBasketPrequalSDK = isSSR
        ? false
        : strToBool(getPageSetting("show-prequal-cart"));
    const basketURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("basket-summary");
    const checkoutURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("checkout-index");
    const retailURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("find-a-retailer");
    const isPageInsideIFrame = isSSR ? false : isPageInsideIFrameCheck();
    const isAdmin = isSSR
        ? false
        : !(document.querySelector(".csr-toolbar") === null);
    useEffect(() => {
        // Including legacy EventListener code during the ongoing RichNavigation refactor work, -@zlebar
        Array.from(
            document.querySelectorAll<HTMLElement>(".mobile-toggle"),
        ).forEach((elem) => {
            elem.addEventListener(
                "click",
                buildMobileNavToggleClickHandler(elem),
            );
        });
    }, []);
    return (
        <div className="header__container container">
            <nav className="header__main-menu-nav">
                <a className="header__screen-reader-skip" href="#main-content">
                    Skip to main content
                </a>
                <a
                    className="header__logo"
                    href="/"
                    title="Sealy Home"
                    target="_top"
                >
                    <svg
                        className="desktop-only"
                        role="img"
                        aria-labelledby="desktop-only-logo"
                    >
                        <title id="desktop-only-logo">Sealy Homepage</title>
                        <use xlinkHref="#bowtie" />
                    </svg>
                    <svg
                        className="mobile-only"
                        role="img"
                        aria-labelledby="mobile-only-logo"
                    >
                        <title id="mobile-only-logo">Sealy Homepage</title>
                        <use xlinkHref="#wordmark" />
                    </svg>
                </a>
                <div className={mainMenuClasses}>
                    <MainMenu
                        isAdmin={isAdmin}
                        menuItems={JSON.parse(props.nav_menu)}
                    />
                </div>
                <button className="mobile-toggle">
                    <span className="burger-icon"></span>
                </button>
            </nav>
            <div className="header__middle-gap"></div>
            <nav className="header__utility-nav">
                <Link
                    className="header__nav-item"
                    title="Find a Retailer"
                    href={retailURL}
                    target="_top"
                >
                    <svg>
                        <title>Find a Retailer Icon</title>
                        <use xlinkHref="#icon-pin" />
                    </svg>{" "}
                    <span className="is-desktop">Find a Retailer</span>
                </Link>
                <span className="header__utility-nav__auth-login">
                    <Provider store={store}>
                        <AccountMenu />
                    </Provider>
                </span>
                <div className="site-nav__secondary-menu__item">
                    <Provider store={store}>
                        <BasketMenuIcon
                            basketURL={basketURL}
                            checkoutURL={checkoutURL}
                            isPageInsideIFrame={isPageInsideIFrame}
                            basketLineVariant={
                                BasketLineVariant.MINIMAL_ENHANCED
                            }
                            buildPromoComponents={(closeModal) => {
                                if (!enableBasketPrequalSDK) {
                                    return null;
                                }
                                return (
                                    <Provider store={store}>
                                        <FinancingModalTriggerMinicart
                                            closeModal={closeModal}
                                        />
                                    </Provider>
                                );
                            }}
                        />
                    </Provider>
                </div>
            </nav>
        </div>
    );
};
